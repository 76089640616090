.table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.table {
  padding: 0 20px;
}

table {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  border: 0.1px solid rgba(0, 0, 0, 0);
}

.tableRow,
.body {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  border-bottom: 1px solid #ddd;
  /* width: 100%; */
}

.tableHeader,
.tableCell {
  text-overflow: ellipsis;
  /* overflow: hidden; */
  padding: 8px !important;
  /* min-width: 230px; */
  /* max-width: 230px; */
  white-space: nowrap;
  text-align: start;
}

th.tableCell {
  padding: 18px !important;
}

.tableHeader {
  font-weight: bold;
  background-color: #f2f2f2;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-evenly;
}
.tableBody {
  width: 100%;
}

/* .tableCell,
th {
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
} */

/* Optional: Adjust the styles as needed */

.table {
  width: 100%;
  white-space: none;
  overflow-y: auto;
  min-height: 160px;
}

.row-class {
  display: flex;
  flex-direction: column;
}

.tableBody {
  flex-direction: column;
  align-items: flex-start;
}

/* .tableRow span {
  background-color: transparent !important;
} */
input[type="search"] {
  border: 1px solid grey;
  outline: none;
  border-radius: 5px;
}

.tableRow {
  border: 0.1px solid #8080802e;
  border-radius: 5px;
}
td.tableCell input[type="checkbox"] {
  width: 100%;
  height: 25px;
  border: 0.1px solid;
  cursor: pointer;
  background: green;
}
.filters {
  display: flex;
  gap: 14px;
  align-items: center;
}

.filters svg {
  font-size: 10px;
  width: 17px !important;
  height: 17px !important;
  color: #3d1585;
  border-radius: 5px;
}

.flex-box {
  gap: 10px !important;
  display: flex;
}

tbody.tableRow.tableBody h4 {
  text-align: center;
  margin: auto;
  font-size: 14px;
}

td img {
  width: 120px;
  border-radius: 7px;
}

.menu_Open_close .open_close + div > * {
  width: 100%;
}

.menu_Open_close .open_close + div {
  position: absolute;
  z-index: 99;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  top: 100%;
  gap: 10px;
  border-radius: 8px;
  box-shadow: 1px -2px 24px 2px #80808070;
}

.menu_Open_close .open_close + div.above {
  bottom: 100%;
}

.menu_Open_close .open_close + div.bottom {
  top: 100%;
}

.open_close {
  width: 100%;
  text-align: center;
  height: 100%;
  font-weight: 800;
  font-size: 22px;
}

th.tableHeader {
  padding: 20px 10px !important;
}

button:focus,
button:valid {
  border: none !important;
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 5px 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.pagination p {
  margin: 0;
}
.pagination > div p {
  margin: 0;
}

.pagination > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination p {
  margin: 0;
  display: flex;
}
@media (max-width: 450px) {
  .pagination button {
    height: 30px;
    width: 30px;
    font-size: 13px;
    gap: 4px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sorts {
  display: flex;
  gap: 11px;
  align-items: center;
}

.sorts input {
  width: 100px;
  font-size: 14px;
}

.sorts span {
  color: #3d1585;
  cursor: pointer;
}

td.tableCell > span {
  color: white !important;
  font-weight: 800;
}
