.folders {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 20px;
}

.folder {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  text-align: center;
  padding: 15px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.folder:hover {
  background-color: #f0f4f8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.folder .icon {
  margin-bottom: 12px;
}

.folder .icon img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.folder .name {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
}

h4 {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
  margin-top: 30px;
}
