.tags-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
}

.tag {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 4px;
  display: inline-flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tag-delete-button {
  background-color: transparent;
  border: none;
  color: #999;
  cursor: pointer;
  margin-left: 8px;
}
/* 
.tag-delete-button:hover {
  color: #666;
} */

.tag-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  margin-top: 8px;
  margin-right: 8px;
  border-radius: 20px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* .submit-button:hover {
  background-color: #0056b3;
} */

/* .tag {
  display: inline-block;
  border-radius: 3px;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 2px;
  background: var(--tag-bg);
  color: var(--text-color);
  font-weight: 600;
  margin: 0.25em 0.1em;
} */

.tag button {
  background-color: #1f9f63;
  color: white;
  /* padding: 10px; */
  /* width: 100px; */
  margin-right: 10px !important;
  font-size: 18px !important;
  height: 20px !important;
  border-radius: 50% !important;
  width: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tags-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}
