.addquestionmcq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.addquestionmcq>h4 {
  text-transform: capitalize;
  font-size: 22px;
}

.addquestionmcq>svg {
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;
}