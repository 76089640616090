.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
}

audio.audio {
  width: 300px !important;
  height: 40px !important;
}
.dropdown-options {
  position: absolute;
  top: 100%;
  max-height: 200px;
  overflow: auto;
  left: -100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;
  margin-top: 5px;
}

.dropdown-options div {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.dropdown-options h4 {
  margin-bottom: 5px;
  color: #3498db;
}

.dropdown-options span {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.dropdown-options span.selected {
  background-color: #3498db;
  color: #fff;
}
.dropdown-header {
  display: block;
  /* padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x); */
  margin-bottom: 0;
  font-size: 0.765625rem;
  /* color: var(--bs-dropdown-header-color); */
  white-space: nowrap;
  padding: 10px !important;
  color: white !important;
  width: 100%;
  background: red !important;
}

.mb-3 .custom-dropdown {
  width: 100% !important;
}

.mb-3 .dropdown-options {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 700px;
  overflow: auto;
  left: 0 !important;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;
  margin-top: 5px;
}

select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0.1px solid darkgrey;
}
