.inputField span {
  position: absolute;
  right: 3%;
  border-radius: 4px;
  top: 50%;
  translate: 0 -50%;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu {
  z-index: 9999999 !important;
}

.r_btns {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.r_btns input {
  margin: 0 !important;
}

.radioBtns {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.awssld__wrapper {
  min-height: 400px !important;
  max-height: 600px;
}

.select__menu {
  z-index: 12261535236546 !important;
}
