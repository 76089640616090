.interacttype {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 10px;
}

.interacttype>button {
  width: 200px;
  max-width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #ccc;
  text-transform: capitalize;
  transition: .4s all ease-in-out;
}

.interacttype>button.active {
  background-color: #0d6efd;
  color: white;
}

.header-btns {
  margin: 12px 0 !important;
  display: flex !important;
  gap: 10px !important;
  justify-content: center !important;
  align-items: center !important;
}

.card-flid {
  background-color: transparent;
  width: 90%;
  margin: 10px auto;
  height: 146px;
  perspective: 1000px;
  transform: scale(1);

}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  transform-style: preserve-3d;
  /* transform-origin: 100% bottom; */
}

.card-flid.back .card-inner,
.card-flid:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 130px;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/homeCard.png"), linear-gradient(to top, #ea4a5f, #ea4a5f);
  background-size: cover;
  background-position: top;
  font-size: 20px;
  font-weight: 700;
}

.card-front p {
  padding-left: 20px;
  padding-top: 7px;
  font-size: 30px;
  font-weight: 500;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.student_infoflash {
  width: 90%;
  min-height: 100px;
  /* background-color: #dfedfe; */
  margin: auto;
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.student_infoflash>div>* {
  margin: 0px;
  text-align: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  min-height: fit-content !important;
}

.table-responsive.react-table {
  overflow-x: auto !important;
}

.inputField.withtext span {
  display: none;
  position: relative !important;
}

.inputField.withtext{
  overflow: visible !important;
}