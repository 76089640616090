.inputField {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}
.withtext {
  flex-direction: column;
}

.inputField:not(.withtext) {
  align-items: center;
  width: min(200px, 100%);
}

form label {
  cursor: pointer;
  user-select: none;
  display: block;
}

.inputField:not(.withtext) > label {
  padding: 10px;
  background-color: beige;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}

.inputField:not(.withtext) > label.active {
  background-color: brown;
  color: white;
}

.checkedBoxes {
  width: 100%;
  display: flex;
  /* align-items: center; */
  padding: 10px 0;
  gap: 18px;
  flex-wrap: wrap;
}

.inputField {
  flex-direction: column;
  width: 100%;
}

.inputField input {
  width: 100%;
}

.col-lg-6 {
  width: 100% !important;
}

.card {
  gap: 10px !important;
}

.card-body {
  background-color: var(--bs-card-bg);
}

.cardSolutions,
.Statistics_card,
.Statistics_card > .card-body {
  background: none !important;
}

.Statistics_card .page-content {
  padding-top: 0 !important;
}

.stats_bottom canvas {
  width: 230px !important;
  height: 230px !important;
}

.stats_bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 90px;
  margin: 20px 0;
}

.stats_bottom.st_sib {
  flex-direction: column;
  gap: 4px !important;
}

.label_item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label_Color {
  width: 20px;
  height: 20px;
  border-radius: 40px;
}

.labelsColors {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.stats_top {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.copyText {
  color: green;
  font-size: 19px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  height: fit-content;
}

p.res_nums {
  color: #80808082;
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pagination span {
  cursor: pointer;
}

.qList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basic-multi-select > span {
  display: none !important;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.custom-table th, .custom-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.custom-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.check-symbol {
  text-align: center;
}

.check-true {
  color: green;
  font-size: 24px;
}

.check-false {
  color: red;
  font-size: 24px;
}

.no-questions {
  text-align: center;
  font-size: 20px;
  color: #999;
  padding: 20px;
}
