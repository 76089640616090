.time-picker-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

/* .time-input {
  width: 30px;
  margin: 0 5px;
  padding: 5px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
} */

/* Add more styling as needed */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
