body[data-sidebar="dark"] .vertical-menu,
body[data-sidebar="dark"] .navbar-brand-box {
  background-color: #f8f9fa !important;
  border: #f8f9fa !important;
}
body[data-sidebar="dark"] .vertical-menu {
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.1), 1px 0 2px -1px rgba(0, 0, 0, 0.1);
}
#sidebar-menu .metismenu .menu-item,
.bx {
  color: #495057 !important;
}
#sidebar-menu .metismenu {
  margin: 10px 0;
}
body[data-sidebar="dark"] .menu-title {
  color: #9ba5af !important;
}

.modal-button.mt-2 {
  top: 10px !important;
}

.card-body {
  padding-top: 0px !important;
}

div#table-invoices-list > *:first-child {
  align-items: center;
  background: white !important;
  padding: 20px 10px 0;
  border-radius: 10px;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-bottom: 0 !important;
}

.search-box,
.search-box label,
.modal-button.mt-2,
.modal-button button {
  margin: 0 !important;
}

div#table-invoices-list > *:first-child {
  border-bottom: 2px solid#f8f9fa !important;
  padding: 10px;
}

.courseContainerCard,
.courseContainerCard .course_name span {
  display: flex;
  font-size: 14px !important;
}

.courseContainerCard .course_name {
  border: none !important;
}

.courseContainerCard img,
.courseContainerCardImg {
  object-fit: contain;
  width: 100px !important;
  margin: 0 10px 0 0;
  border-radius: 10px !important;
}

.course_table tr {
  width: 350px;
  margin: 10px 10px 0 0 !important;
  border-radius: 8px;
  padding-left: 12px;
  border-color: transparent !important;
  box-shadow: 1px 2px 24px -2px #80808047;
}

.courseContainer > *,
.course_table tbody {
  justify-content: space-between !important;
  /* gap: 6px; */
  margin: 0 !important;
}

.course_table tr td {
  /* padding: 0; */
  padding: 10px;
}

.course_table tr:hover {
  background: transparent !important;
}

.course_table,
.course_table tbody {
  border: none !important;
  padding: 0 !important;
  padding: 10px 5px !important;
  border: none !important;
  background: transparent !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.course_table tbody {
  display: flex;
  width: 100%;
}

body[data-sidebar="dark"] #sidebar-menu ul li.mm-active .mm-show > a {
  color: #ffffff !important;
  margin: 10px;
}
.filter_stu {
  display: none !important;
}

.card-body {
  padding: 0 !important;
}
.card-body,
.card {
  background: transparent !important;
}

.univs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.univs-section,
.grades-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.univ-item,
.grade-item {
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 500;
  color: #333;
}

.univ-item:hover,
.grade-item:hover {
  background-color: #ddd;
}

.univs-section .active,
.grades-section .active {
  background-color: rgb(241 79 109);
  color: white;
  border: 1px solid rgb(241 79 109);
}

@media screen and (max-width: 768px) {
  .univs-section,
  .grades-section {
    justify-content: center;
  }

  .univ-item,
  .grade-item {
    width: 100%;
    text-align: center;
  }
}

#sidebar-menu ul li.mm-active > a {
  background-color: rgb(241 79 109) !important;
  color: white !important;
}
#sidebar-menu .metismenu .active span.menu-item {
  color: white !important;
}
.active span.menu-item {
  background: none !important;
}

div#table-invoices-list > *:first-child {
  padding: 10px;
}

.table-responsive.react-table table {
  overflow-x: auto !important;
  background: white !important;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
  background: white !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-left: none !important;
  border-width: 0;
  border-right: none !important;
}

.card {
  box-shadow: none !important;
}

table {
  border-radius: 10px !important;
}

.table-responsive.react-table {
  border-radius: 19px;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.sub-menu li.mm-active a {
  width: 85% !important;
  margin: 10px auto !important;
  font-size: 12px !important;
  text-align: start !important;
  padding-left: 10px !important;
}

#side-menu > li.mm-active > a > span.menu-item {
  color: white !important;
}

button#page-header-user-dropdown {
  width: fit-content !important;
  justify-content: center !important;
}

form.app-search.d-none.d-lg-block > * {
  border: 2px solid !important;
  border-radius: 28px !important;
  width: 300px !important;
  background: transparent !important;
}

div#table-invoices-list > *:first-child {
  padding: 10px;
  width: 100%;
  margin: auto;
}

.filtersTables {
  display: flex;
  gap: 10px;
}

.filtersTablesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersTablesContainer > * {
  width: fit-content;
}

#search-bar-0 {
  width: 300px !important;
}

div#layout-wrapper {
  min-height: 100vh !important;
}

/* Container */
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.toggle-btn {
  padding: 8px 16px;
  margin-right: 8px;
  border: 1px solid #007bff;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  border-radius: 4px;
}

.toggle-btn.active {
  background-color: #007bff;
  color: #ffffff;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

#searchDiv {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 250px;
}

.search-btn {
  background-color: #28a745;
  color: #ffffff;
}

.reset-btn {
  background-color: #dc3545;
  color: #ffffff;
}

.load-more {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 4px;
}

.MCQContainer {
  margin-top: 100px !important;
  width: 100% !important;
  padding: 0 20px !important;
}

.filtersTablesContainer,
.table-responsive.react-table table {
  margin: 0 !important;
}

/* div#table-invoices-list > *:first-child{border} */

.MCQContainer table tbody tr td > * {
  font-size: 14px !important;
  white-space: nowrap;
}

.MCQContainer
  #table-invoices-list
  > div.table-responsive.react-table
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  font-size: 13px !important;
  min-width: 350px !important;
}

.pagination-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination-btn {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #007bff;
  color: #007bff;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-btn:disabled {
  border-color: #ddd;
  color: #ddd;
}

.pagination-btn:hover:not(:disabled) {
  color: #fff;
  background-color: #007bff;
}

.pagination-input {
  width: 70px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 4px;
}

.pagination-row {
  display: flex !important;
  flex-wrap: nowrap !important;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 20px 0 !important;
  margin-right: auto !important;
  position: sticky;
  left: 0;
}

.pagination-row > * {
  width: fit-content !important;
}

.pagination-btn {
  padding: 4px 8px;
  font-size: 14px;
  border: none;
  color: #007bff;
  background: transparent;
  cursor: pointer;
  width: fit-content !important;
}

.pagination-btn:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-input {
  width: 50px;
  text-align: center;
  width: fit-content !important;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px;
}

.checkout-billinginfo-card-sub form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 600px; */
}

/* عناوين الأقسام */
.checkout-billinginfo-card-sub .form-label {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  display: inline-block;
}

.checkout-billinginfo-card-sub .mb-3,
.checkout-billinginfo-card-sub .mb-4 {
  margin-bottom: 1.5rem;
}

/* حقول الإدخال */
.checkout-billinginfo-card-sub .form-control {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.checkout-billinginfo-card-sub .form-control:focus {
  border-color: #3498db;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.1);
}
.checkout-billinginfo-card-sub .univs {
  width: 100%;
  display: flex;
  gap: 16px;
  margin: 20px 0;
  align-items: center;
  /* overflow-y: hidden; */
  flex-wrap: wrap;
}
/* عناصر القائمة القابلة للتحديد */
.checkout-billinginfo-card-sub .univs span,
.checkout-billinginfo-card-sub .subjects_av li {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #e0e0e0;
  color: #333;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkout-billinginfo-card-sub .univs span.active,
.checkout-billinginfo-card-sub .subjects_av li.btn-success {
  background-color: #3498db;
  color: #ffffff;
}

.checkout-billinginfo-card-sub .univs span:hover,
.checkout-billinginfo-card-sub .subjects_av li:hover {
  background-color: #2980b9;
  color: #ffffff;
}

/* قائمة المواد */
.checkout-billinginfo-card-sub .subjects_av {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  /* max-height: 150px; */
  /* overflow-y: auto; */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.checkout-billinginfo-card-sub .subjects_av h2 {
  text-align: center;
  color: #777;
  font-size: 16px;
}

/* أزرار الإجراءات */
.checkout-billinginfo-card-sub .btn {
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.checkout-billinginfo-card-sub .btn-danger {
  background-color: transparent;
  color: #e74c3c;
  border: 1px solid #e74c3c;
}

.checkout-billinginfo-card-sub .btn-danger:hover {
  background-color: #e74c3c;
  color: #ffffff;
}

.checkout-billinginfo-card-sub .btn-success {
  background-color: #2ecc71;
  color: #ffffff;
  border: none;
}

.btn-success:hover {
  background-color: #27ae60;
}

/* أيقونات */
.checkout-billinginfo-card-sub .bx {
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 5px;
}

/* محاذاة الإجراءات */
.checkout-billinginfo-card-sub .text-end {
  text-align: right;
}

/* قائمة العناصر المحاذية */
.checkout-billinginfo-card-sub .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.checkout-billinginfo-card-sub .col {
  flex: 1;
  padding: 0 10px;
}

.checkout-billinginfo-card-sub .col-4 {
  flex: 0 0 33.3333%;
}

@media (max-width: 768px) {
  .checkout-billinginfo-card-sub .col-4 {
    flex: 0 0 100%;
  }
}

.copounDate {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, auto);
}
.copounDate .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 7px;
  padding-top: 7px;
  height: 48px !important;
}

.filtersTablesContainer > * {
  width: fit-content !important;
}

.header-btns {
  width: fit-content !important;
}



/* ==================== */


.modal-content form.grid {
  display: grid !important;
  grid-template-columns: repeat(2, auto) !important;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap:5px;
flex-wrap:wrap;
  flex-direction: row-reverse !important;
}

.btn-close{
  position: absolute;
  right:0px;
  background:white;
  z-index:1201;
  width:40px;
  height:40px;
  border-radius:8px;
  top:0
}

/* ---------------- */


/* Form Container Styling */
form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

/* Gallery Container */
.links_images.overAllImages {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: center;
}

/* Individual Image Card */
.postImage {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.postImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Delete Button Styling */
.postImage .btn-danger {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
  background-color: #e74c3c;
  color: #ffffff;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.postImage .btn-danger:hover {
  background-color: #c0392b;
}

/* Section Heading */
h3 {
  color: #333;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

/* New Images Input Container */
.links_new_images {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Add & Update Buttons */
.btn-success {
  background-color: #2ecc71;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  display: block;
  text-align: center;
  transition: background-color 0.3s ease;
}

.btn-success:hover {
  background-color: #27ae60;
}

.btn-success:disabled,
.btn-danger:disabled {
  background-color: #b3b3b3;
  cursor: not-allowed;
}


.links_new_images .field_input,.links_new_images  .input_fields{
  width:100%
}

form {

  width: 100% !important;

}


/* Table Container Styling */
.table-responsive.react-table {
  background-color: #fff !important;
  padding: 20px !important;
  /* border-radius: 10px !important; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important; */
}

/* Table Styling */
.react-table table {
  width: 100% !important;
  border-collapse: collapse !important;
}

.react-table th,
.react-table td {
  padding: 12px 15px !important;
  border: 1px solid #ddd !important;
  text-align: left !important;
  font-size: 14px !important;
}

.react-table th {
  background-color: #f5f5f5 !important;
  color: #333 !important;
  font-weight: bold !important;
}

.react-table tbody tr:nth-child(even) {
  background-color: #f9f9f9 !important;
}

.react-table tbody tr:hover {
  background-color: #eaf6ff !important;
}

/* Table Header */
.table-light.table-nowrap th div {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

/* Sorting Indicator */
.table-light.table-nowrap th div .sorting-icon {
  margin-left: 8px !important;
  font-size: 12px !important;
}

/* Pagination Styling */
.pagination-row {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 20px !important;
  gap: 10px !important;
}

.pagination-btn {
  background-color: #3498db !important;
  color: #fff !important;
  border: none !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.pagination-btn:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.pagination-btn:hover:not(:disabled) {
  background-color: #2980b9 !important;
}

.pagination-input {
  width: 60px !important;
  padding: 5px !important;
  text-align: center !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}

.pagination-input:focus {
  outline: none !important;
  border-color: #3498db !important;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.3) !important;
}

/* Current Page Indicator */
.react-table span {
  font-weight: bold !important;
  color: #333 !important;
}


/* Pagination Container */
.reportPagination {
  background-color: #ffffff !important;
  padding: 15px 20px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  align-items: center !important;
}

.reportPagination .btn {
  border: 2px solid #3498db !important;
  color: #3498db !important;
  font-weight: bold !important;
  padding: 8px 15px !important;
  border-radius: 6px !important;
  transition: all 0.3s ease !important;
}

.reportPagination .btn:hover {
  background-color: #3498db !important;
  color: #ffffff !important;
}

.reportPagination .btn:disabled {
  background-color: #e0e0e0 !important;
  border-color: #ccc !important;
  color: #999 !important;
  cursor: not-allowed !important;
}

/* Pagination Text Styling */
.reportPagination .pagination {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #333 !important;
}

.reportPagination .pagination b {
  color: #666 !important;
}

.reportPagination .pagination .text-danger {
  color: #e74c3c !important;
}

.reportPagination .pagination .text-primary {
  color: #3498db !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .reportPagination {
    flex-direction: column !important;
    text-align: center !important;
    gap: 10px !important;
  }

  .reportPagination .btn {
    width: 100% !important;
  }
}

.filter_students {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.filter_students .stu_her {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.filter_students .form-group {
  flex: 1;
  min-width: 220px;
}

.filter_students .form-group label {
  font-weight: 500;
  margin-bottom: 0.25rem;
  display: block;
  color: #555;
}

.filter_students .form-group input,
.filter_students .form-group .rs-picker {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  padding: 0.5rem 0;
  width: 100%;
}

.filter_students .form-group input:focus,
.filter_students .form-group .rs-picker:focus {
  outline: none;
  border-bottom: 1px solid #007bff;
}

.filter_students .filter-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.filter_students .btn-success {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.filter_students .btn-success:hover {
  background-color: #0056b3;
  cursor: pointer;
}

.filter_students .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #888;
}

.filter_students #table-invoices-list {
  margin-top: 2rem;
}


.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child){
  margin:0 !important
}

.rs-stack-item svg{
  display:none !important
}