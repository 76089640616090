// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #layout-wrapper {
        background-color: $body-bg;
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    .ishorizontal-topbar, .isvertical-topbar {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &[data-sidebar-size="sm"] {
        .ishorizontal-topbar, .isvertical-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }

        .main-content {
            @media (min-width: 769px){
                min-height: 1850px;
            }
        }
    }

    &[data-sidebar-size="md"]{
        .ishorizontal-topbar, .isvertical-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
        }
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    .ishorizontal-topbar, .isvertical-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

// Scrollable layout
body[data-layout-scrollable="true"] {
    @media (min-width: 992px) {
        .ishorizontal-topbar, .isvertical-topbar, .vertical-menu{
            position: absolute;
        }
        .navbar-brand-box {
            position: absolute;
        }
    }
    &[data-layout="horizontal"]{
        @media (min-width: 992px) {
            .ishorizontal-topbar, .isvertical-topbar{
                position: absolute;
            }

            .navbar-brand-box {
                position: relative;
            }
        }
    }
}


body[data-layout-mode="dark"] {
    &[data-layout-size="boxed"] {
        background-color: lighten($gray-dark-200, 4%);
        #layout-wrapper {
            background-color: darken($gray-dark-200, 2%);
        }
    }
}

// Layout Setting Button
.layout-setting-btn {
    position: fixed;
    top: 47.5%;
    right: 0;
    writing-mode: vertical-rl;
    font-weight: 600;
    background-color: $primary;
    color: $white !important;
    line-height: 32px;
    padding: 15px 3px;
    font-size: 16px;
    border-radius: 6px 0 0 6px;
    transform: translateY(-50%);
    z-index: 1999;
}