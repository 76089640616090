.modal_overlay {
  position: fixed;
  background: #0000004f !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}

.header_text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.C_modal {
  /* position: fixed; */
  /* top: 25%; */
  left: 50%;
  position: absolute;
  translate: -50% 0%;
  box-shadow: 1px 2px 24px -2px #80808066;
  border-radius: 6px;
  padding: 20px;
  z-index: 99999999999999999;
  background: white;
  width: min(500px, 100%);
  display: flex;
  flex-direction: column;
  min-height: -moz-fit-content;
  /* min-height: 100% !important; */
  margin-bottom: 15px;
  height: 100% !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding-bottom: 30px;
}

.model_container {
  position: fixed;
  top: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  z-index: 99999999999999999;
  margin-top: 30px !important;
}

.modal_body > * {
  width: 100%;
  white-space: pre-line;
}

.modal_header {
  width: 100%;
  padding: 10px;
  border-bottom: 0.1px solid;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header_text span {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
}

.modal_body {
  padding: 6px 20px !important;
  width: 100% !important;
  height: 100% !important;
  /* margin-bottom: 30px !important; */
}

.btn {
  text-align: center !important;
}

.css-g10jdo {
  right: auto !important;
  left: 0 !important;
}
