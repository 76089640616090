.MR_input_form_element {
  width: 100%;
  padding: 6px;
  cursor: pointer;
  border: 0.1px solid #80808057;
  border-radius: 5px;
  outline: none;
  margin: 5px 0;
}

.inputField button {
  margin-top: 28px;
}

.image {
  width: 100%;
  margin: 12px 0 19px;
}

.image .fileUploadedImage {
  border-radius: 7px;
  width: 90%;
}

.image {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.MR_textarea_form_element {
  display: block;
  width: 100%;
  border: 1px solid #e9e9e9;
  padding: 6px;
  border-radius: 5px;
  resize: vertical;
  margin: 10px 0;
  min-height: 90px;
}

input.MR_input_form_element.lazy {
  background-color: transparent;
  border: none;
}

select {
  padding: 5px 10px;
  word-wrap: normal;
  border: 0.1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
}
