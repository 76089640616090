.add_newanstwee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_newanstwee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tweet_ans {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.tweet_ans textarea {
  width: 80%;
}

.add_newanstwee svg {
  color: #27a12f;
  font-size: 22px;
  cursor: pointer;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px;
  height: 108px !important;
}

.quill {
  min-height: fit-content !important;
}
