.unitVideosList .table-responsive table tr {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: min(400px, 100%) !important;
  flex-direction: column;
  box-shadow: 1px 0px 24px -2px #80808061;
  border-radius: 6px;
  padding: 10px;
}

.unitVideosList .table-responsive table thead {
  display: none !important;
}

.unitVideosList .table-responsive table tbody {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap;
  width: 100% !important;
  gap: 15px;
  padding: 20px;
}

.unitVideosList .table-responsive table tr td {
  border: none !important;
  width: 100% !important;
}

.unitVideosList .table-responsive table td .dropdown-menu-end.dropdown-menu {
  display: flex;
  position: relative !important;
  inset: 0 !important;
  border: none !important;
  transform: none !important;
  box-shadow: none !important;
  text-align: center;
  justify-content: center;
}

.unitVideosList .table-responsive table td .dropdown-menu-end.dropdown-menu {
  /* padding: 10px; */
  background: none !important;
  display: flex !important;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

/* 
.unitVideosList .table-responsive table td .dropdown-item{
    margin: 0px 5px;
} */

.unitVideosList .table-responsive table td .dropdown-item {
  width: fit-content !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unitVideosList .table-responsive table td .dropdown-item:hover {
  color: black;
  border-radius: 4px;
  background-color: white;
}

.unitVideosList .table-responsive table td em {
  font-style: normal;
}

.unitVideosList .table-responsive table td p span {
  font-weight: 900;
  padding: 2px 0 !important;
}

.unitVideosList .table-responsive table td p {
  margin: 1px 0;
}

.unitVideosList .table-responsive table td button.btn.btn-light.btn-sm {
  display: none !important;
}

.fideos > *:first-child {
  border-right: 0.1px solid #80808033;
}

.fideos > * {
  padding: 10px;
}

.fideos {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  gap: 10px;
  flex-wrap: wrap;
}

.modal-dialog.modal-body {
  width: min(850px, 100%) !important;
  max-width: none !important;
}

.video_details .details {
  display: flex;
  width: 100%;
  gap: 14px;
  align-items: center;
  justify-content: space-evenly;
}

.video_details p {
  font-weight: 600;
  font-size: 16px;
  margin-top: 0;
}

.video_details em {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}

.video_details .src_id {
  padding: 9px 30px;
  border: 2px dotted #008000cb;
  border-radius: 4px;
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.video_details .src_id p {
  font-size: 25px;
  font-weight: 900;
}

.video_details .src_id em {
  font-size: 22px;
  color: green;
  font-weight: 900;
}

#video_details {
  display: flex;
  flex-direction: column;
}

#video_details button {
  margin: auto;
}

.DropVidUn button.dropdown-item {
  font-size: 23px;
}

td {
  vertical-align: middle !important;
}

#table-invoices-list .col-md-1 {
  min-width: 131px !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  /* border: 1px solid #265985; */
  display: flex;
  /* max-height: 100%; */
  min-height: fit-content;
  /* flex-flow: column wrap; */
  align-items: flex-start;
  /* overflow: hidden; */
  position: absolute;
  flex-direction: column !important;
}

.rbc-day-slot.rbc-time-column,
.rbc-day-bg {
  width: 400px !important;
  max-width: auto !important;
}
